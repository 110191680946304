import React, { useState } from 'react'
import { Drawer, Tooltip } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { AppDispatch, RootState } from '../../store'
import './Layout.scss'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import {
  appTitle,
  backgroundImageFilename,
  headerLogoFilename,
  headerLogoMobileFilename,
} from '../../assets/personnalization'
import packageInfo from '../../../package.json'
import HeaderMenuAccount from './HeaderMenuAccount'
import HeaderMenuNavigation from './HeaderMenuNavigation'
import { AiOutlineMenu } from 'react-icons/ai'
import { Helmet } from 'react-helmet-async'
import classNames from 'classnames'
import NotarisationModal from '../BadgesModal/NotarisationModal'
import DigitalSovereigntyModal from '../BadgesModal/DigitalSovereigntyModal'
import MobileMenu from '../MobileMenu/MobileMenu'
import Viewer from '../Viewer/Viewer'

interface Props {
  title?: string
  menu?: React.ReactNode
  content: React.ReactNode
  className?: string
  background?: boolean
  contentFullSize?: boolean
  primaryContent?: boolean
  footer?: boolean
}

function Layout({
  title,
  menu,
  content,
  className,
  background,
  contentFullSize,
  primaryContent,
  footer,
}: Props) {
  const { t, i18n } = useTranslation('layout')
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()
  const auth = useSelector((state: RootState) => state.auth)
  const organisationName = useSelector(
    (state: RootState) => state.organisation.organisation?.name,
  )
  dayjs.extend(duration)
  dayjs.extend(relativeTime)
  dayjs.extend(LocalizedFormat)
  const currentYear = new Date().getFullYear()
  const [openSubMenuDrawer, setOpenSubMenuDrawer] = useState(false)
  const [openNotarisation, setOpenNotarisation] = useState(false)
  const [openSovereignty, setOpenSovereignty] = useState(false)
  const unseen = useSelector((state: RootState) => state.liveNotification.unseen)

  return (
    <>
      <div className={`${className} layout`}>
        <Helmet>
          <title>
            {unseen && unseen > 0 ? `(${unseen}) ` : ''}
            {`${appTitle}${title ? ` - ${title}` : ''}`}
          </title>
          <link
            rel="icon"
            type="image/png"
            href={unseen && unseen > 0 ? '/icon_notif.png' : '/icon.png'}
          ></link>
        </Helmet>
        <header>
          {menu && (
            <div className="page-burger-menu-container">
              <AiOutlineMenu
                className="styled-icon"
                size="2.5em"
                onClick={() => setOpenSubMenuDrawer(true)}
              />
            </div>
          )}
          <div
            className={classNames('header-left', {
              'header-left--light': !menu && !title,
            })}
          >
            <Link to="/applications" className="logo">
              <Tooltip
                title={`${t('Version:', { ns: 'common' })} ${packageInfo.version}`}
              >
                <img
                  className="logo-image"
                  src={`/logo/${headerLogoFilename}`}
                  alt="Auxasuite logo large"
                />
              </Tooltip>

              <img
                className="logo-image-small"
                src={`/logo/${headerLogoMobileFilename}`}
                alt="Auxasuite logo"
              />
            </Link>
          </div>
          {auth && auth.firstName && auth.lastName && (
            <div
              className={classNames('header-right-container', {
                'header-right-container--bordered': menu && title,
              })}
            >
              <div>{title && <h1 className="title">{title}</h1>}</div>
              <div className="header-right">
                <HeaderMenuNavigation />
                <div className="d-flex d-flex-column title-header">
                  <div
                    className="text-one-line mw-11rem name-legend cursor-pointer"
                    onClick={() => navigate('/account')}
                  >{`${auth.firstName} ${auth.lastName?.toLocaleUpperCase()}`}</div>
                  <div
                    className="text-secondary-color italic title-header-legend mw-11rem cursor-pointer"
                    onClick={() => navigate('/organization')}
                  >
                    {organisationName}
                  </div>
                </div>
                <HeaderMenuAccount />
              </div>
              <MobileMenu />
            </div>
          )}
        </header>
        <main
          className="background"
          style={
            background
              ? {
                  backgroundImage: `linear-gradient(rgb(27, 71, 120) 0%, rgba(27, 71, 120, 0.7) 20%, rgb(170 185 195 / 33%) 50%, rgb(170 185 195 / 81%) 70%, rgb(255, 255, 255)), url(/images/${backgroundImageFilename})`,
                }
              : {}
          }
        >
          <div className={classNames('content', { 'content--primary': primaryContent })}>
            {title && menu && (
              <>
                <aside>{menu}</aside>
                <Drawer
                  title={<h1 className="title">{title}</h1>}
                  open={openSubMenuDrawer}
                  placement="left"
                  className="right-side-drawer"
                  onClose={() => setOpenSubMenuDrawer(false)}
                >
                  {menu}
                </Drawer>
              </>
            )}
            <article
              className={classNames(
                'article',
                { 'article--no-border': !menu },
                { 'article--no-padding': contentFullSize },
              )}
            >
              {content}
            </article>
          </div>
        </main>
        {footer && (
          <footer className={classNames('footer', { 'footer--primary': primaryContent })}>
            Auxasuite {currentYear} -&nbsp;
            <a
              className="footer-document"
              target="_blank"
              rel="noreferrer"
              href={`${process.env.REACT_APP_STATIC_ASSETS_BASE_URL}/legal-notice_${i18n.language}.pdf`}
            >
              {t('Legal notice', { ns: 'common' })}
            </a>
            <span>&nbsp;-&nbsp;</span>
            <a
              className="footer-document"
              target="_blank"
              rel="noreferrer"
              href={`${process.env.REACT_APP_STATIC_ASSETS_BASE_URL}/cgu_${i18n.language}.pdf`}
            >
              {t('Terms and conditions of service', { ns: 'common' })}
            </a>
            <div className="badges-container">
              <div
                className="sovereignty-container"
                onClick={() => setOpenSovereignty(true)}
              >
                <img src={`/images/sovereignty_${i18n.language}.png`} />
              </div>
              <div
                className="notarisation-container"
                onClick={() => setOpenNotarisation(true)}
              >
                <img src={`/images/notarisation_${i18n.language}.png`} />
              </div>
            </div>
          </footer>
        )}
      </div>

      <NotarisationModal
        openNotarisation={openNotarisation}
        setOpenNotarisation={setOpenNotarisation}
      />

      <DigitalSovereigntyModal
        openSovereignty={openSovereignty}
        setOpenSovereignty={setOpenSovereignty}
      />

      <Viewer />
    </>
  )
}

export default Layout
