import { BiPowerOff } from 'react-icons/bi'
import { Button, Divider, Drawer, Menu, Table, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { VscAccount } from 'react-icons/vsc'
import {
  AiOutlineEye,
  AiOutlineMenu,
  AiOutlineVideoCameraAdd,
  AiOutlineEdit,
} from 'react-icons/ai'
import { BsCalendar3 } from 'react-icons/bs'
import { FiPower } from 'react-icons/fi'
import { LanguageSwitchPreview } from './LanguageSwitchPreview'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import {
  headerLogoFilename,
  headerLogoMobileFilename,
} from '../../../assets/personnalization'
import { IoIosAdd } from 'react-icons/io'
import { RoomInterface } from '../../room/RoomInterface'
import { ColumnsType } from 'antd/lib/table'
import { IoTrashOutline } from 'react-icons/io5'
import { localFormatDate } from '../../../utils/Utils'
import humanizeDuration from 'humanize-duration'

interface Props {
  logo: string | undefined
  logoMobile: string | undefined
}

function LayoutPreview({ logo, logoMobile }: Props) {
  const { i18n, t } = useTranslation('layout')
  dayjs.extend(duration)
  dayjs.extend(relativeTime)
  dayjs.extend(LocalizedFormat)
  const currentYear = new Date().getFullYear()

  const items = [
    {
      key: 'unused',
      label: <span>Lorem Ipsum</span>,
      icon: <VscAccount size="1.2em" className="account-icon" />,
      className: 'name-line',
      disabled: true,
    },
    {
      key: 'meetings',
      label: t('Meetings'),
      disabled: true,
      children: [
        {
          key: 'new-meeting',
          label: t('New meeting'),
          isOpen: true,
        },
        {
          key: 'upcoming-meetings',
          label: t('Upcoming meetings'),
        },
        {
          key: 'archived-meetings',
          label: t('Archived meetings'),
        },
      ],
    },
    {
      key: 'account',
      label: t('Account'),
    },
  ]

  const [open, setOpen] = useState(false)

  const showDrawer = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  function renderDrawer() {
    return (
      <div className="hideOnDesktop">
        <AiOutlineMenu className="styled-icon" size="2.5em" onClick={showDrawer} />
        <Drawer
          rootClassName="preview"
          width="18rem"
          title={
            <div className="drawer-title">
              <VscAccount size="1.2em" className="account-icon" />
              <span>Lorem Ipsum</span>
            </div>
          }
          placement="right"
          onClose={onClose}
          open={open}
        >
          <Menu
            mode="inline"
            defaultSelectedKeys={
              (location.pathname === '/new-meeting' && ['new-meeting']) ||
              (location.pathname === '/upcoming-meetings' && ['upcoming-meetings']) ||
              (location.pathname === '/archived-meetings' && ['archived-meetings']) ||
              (location.pathname === '/myorganisation' && ['myorganisation']) ||
              (location.pathname === '/account' && ['account']) ||
              (location.pathname === '/personalization' && ['personalization']) ||
              []
            }
            defaultOpenKeys={['meetings']}
            items={items.slice(1)}
          />
          <div className="p-1rem">
            <LanguageSwitchPreview />
          </div>
          <div className="drawer-disconnect-container">
            <Button className="drawer-logout-btn btn-secondary" type="default">
              <FiPower />
              <span>{t('Log out')}</span>
            </Button>
          </div>
        </Drawer>
      </div>
    )
  }

  const upcoming: RoomInterface[] = [
    {
      creator: {
        email: 'moderator@company.fr',
        firstName: 'Mode',
        lastName: 'Rator',
      },
      archived: false,
      participants: [],
      invited: [],
      files: [],
      duration: 30,
      name: '[ 1 ] Lorem ipsum dolor sit amet',
      startDate: dayjs().subtract(1, 'day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
      id: '1',
    },
    {
      creator: {
        email: 'moderator@company.fr',
        firstName: 'Mode',
        lastName: 'Rator',
      },
      archived: false,
      participants: [],
      invited: [],
      files: [],
      duration: 30,
      name: '[ 2 ] Lorem ipsum dolor sit amet',
      startDate: dayjs()
        .subtract(1, 'day')
        .subtract(1, 'hour')
        .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
      id: '2',
    },
    {
      creator: {
        email: 'moderator@company.fr',
        firstName: 'Mode',
        lastName: 'Rator',
      },
      archived: false,
      participants: [],
      invited: [],
      files: [],
      duration: 30,
      name: '[ 3 ] Lorem ipsum dolor sit amet',
      startDate: dayjs()
        .subtract(1, 'day')
        .subtract(2, 'hour')
        .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
      id: '3',
    },
    {
      creator: {
        email: 'moderator@company.fr',
        firstName: 'Mode',
        lastName: 'Rator',
      },
      archived: false,
      participants: [],
      invited: [],
      files: [],
      duration: 30,
      name: '[ 4 ] Lorem ipsum dolor sit amet',
      startDate: dayjs().add(1, 'day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
      id: '4',
    },
    {
      creator: {
        email: 'moderator@company.fr',
        firstName: 'Mode',
        lastName: 'Rator',
      },
      archived: false,
      participants: [],
      invited: [],
      files: [],
      duration: 30,
      name: '[ 5 ] Lorem ipsum dolor sit amet',
      startDate: dayjs()
        .add(1, 'day')
        .add(1, 'hour')
        .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
      id: '5',
    },
    {
      creator: {
        email: 'moderator@company.fr',
        firstName: 'Mode',
        lastName: 'Rator',
      },
      archived: false,
      participants: [],
      invited: [],
      files: [],
      duration: 30,
      name: '[ 6 ] Lorem ipsum dolor sit amet',
      startDate: dayjs()
        .add(1, 'day')
        .add(2, 'hour')
        .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
      id: '6',
    },
  ]

  const columns: ColumnsType<RoomInterface> = [
    {
      title: t('Date and duration'),
      className: 'date-column',
      render: (room: RoomInterface) =>
        (room.startDate
          ? localFormatDate(dayjs(room.startDate))
          : t('Date is not set', { ns: 'common' })) +
        humanizeDuration(dayjs.duration({ minutes: room.duration }).asMilliseconds(), {
          language: i18n.language,
        }),
      sorter: (a: RoomInterface, b: RoomInterface) => {
        if (!a.startDate && !b.startDate) {
          return 0
        }
        if (!a.startDate) {
          return -1
        }
        if (!b.startDate) {
          return -1
        }
        return dayjs.duration(dayjs(a.startDate).diff(dayjs(b.startDate))).asMinutes()
      },
    },
    {
      title: t('Meeting'),
      dataIndex: 'name',
      className: 'name-column',
    },
    {
      title: t('Created by'),
      className: 'creator-column',
      render: (room: RoomInterface) =>
        room.creator ? (
          <Tooltip title={room.creator.email}>
            {room.creator.firstName} {room.creator.lastName}
          </Tooltip>
        ) : (
          <></>
        ),
    },
    {
      title: t('Created at'),
      className: 'create_dt-column',
      render: (room: RoomInterface) =>
        room.startDate
          ? localFormatDate(dayjs(room.startDate))
          : t('Date is not set', { ns: 'common' }),
    },
    {
      title: t('Actions', { ns: 'common' }),
      render: (room: RoomInterface) => (
        <div className="action-container">
          <Tooltip title={t('Join meeting')} className="cursor-pointer link">
            <Link to={'/room/' + room.id}>
              <AiOutlineVideoCameraAdd size="1.1rem" />
            </Link>
          </Tooltip>
          <Tooltip
            title={
              room.creator?.email === 'moderator@company.com'
                ? t('Visualise meeting')
                : t('Modify meeting')
            }
            className="cursor-pointer"
          >
            {room.creator?.email === 'moderator@company.com' ? (
              <AiOutlineEye size="1.1rem" className="styled-icon" />
            ) : (
              <AiOutlineEdit size="1.1rem" className="styled-icon" />
            )}
          </Tooltip>
          <Tooltip title={t('Delete meeting')} className="cursor-pointer">
            <IoTrashOutline size="1.1rem" className="styled-icon" />
          </Tooltip>
        </div>
      ),
    },
  ]

  return (
    <div className="layout">
      <header>
        <Link to="#" className="logo">
          <img
            className="logo-image"
            src={logo ? logo : `/logo/${headerLogoFilename}`}
            alt="Auxasuite logo large"
          />
          <img
            className="logo-image-small"
            src={logoMobile ? logoMobile : `/logo/${headerLogoMobileFilename}`}
            alt="Auxasuite logo mobile"
          />
        </Link>
        <div className="header-right">
          <span className="header-next_meeting">
            <Link to="#" className="d-flex">
              <span className="mr-05rem d-flex d-flex-middle">
                <BsCalendar3 />
              </span>{' '}
              {t('Next meeting')} {dayjs.duration(1, 'hour').humanize(true)}
            </Link>
          </span>
          {renderDrawer()}
          <div className="hideOnMobile">
            <LanguageSwitchPreview />
          </div>
          <div className="hideOnMobile">
            <Button
              className="ml-1rem logout-button btn-secondary d-flex d-flex-middle"
              type="default"
            >
              <FiPower />
              <span>{t('Log out')}</span>
            </Button>
          </div>
        </div>
      </header>
      <main>
        <aside className="hideOnMobile">
          <Menu
            mode="inline"
            defaultSelectedKeys={
              (location.pathname === '/new-meeting' && ['new-meeting']) ||
              (location.pathname === '/upcoming-meetings' && ['upcoming-meetings']) ||
              (location.pathname === '/archived-meetings' && ['archived-meetings']) ||
              (location.pathname === '/myorganisation' && ['myorganisation']) ||
              (location.pathname === '/account' && ['account']) ||
              []
            }
            defaultOpenKeys={['meetings']}
            items={items}
          />
        </aside>
        <article>
          {/* Meetings */}
          <div className="layout-header">
            <h1>{t('Meetings')}</h1>
            <div className="layout-header-bottom">
              <h4 className="layout-list-header">{t('Your upcoming meetings')}</h4>
              <Button type="primary" icon={<IoIosAdd size="1.5rem" />}>
                <span className="hideOnMobile">{t('Create meeting')}</span>
              </Button>
            </div>
            <Divider />
          </div>
          <Table
            dataSource={upcoming}
            rowKey="id"
            columns={columns}
            pagination={false}
            showSorterTooltip={false}
          />
        </article>
      </main>
      <footer>
        <div>
          Auxasuite {currentYear} -&nbsp;
          <span className="footer-document">{t('Legal notice', { ns: 'common' })}</span>
        </div>
      </footer>
    </div>
  )
}

export default LayoutPreview
